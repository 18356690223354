<template>
  <div class="common-corporation">
    <fieldset class="border-bottom">

      <div class="form-group">
        <label for="legalCorporationName">Legal Corporation Name</label>
        <input type="text" class="form-control" id="legalCorporationName">
      </div>

      <div class="form-group">
        <label for="businessNumber">Business Number
          <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="Business Number (BN) is a 9 digit number that is unique to your business and that is used when dealing with federal, provincial, or local governments. Go to the <a class='link-external' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/register.html' target='_blank' rel='nofollow noopener'>Government of Canada website</a> for help on obtaining a business number." v-popover>
            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
          </a>
        </label>
        <input type="text" class="form-control col-sm-6" id="businessNumber">
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="phone">
            Phone Number
            <a href="#" data-toggle="popover" data-placement="top" data-content="If calling from outside of North America, enter your international country code." v-popover>
            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
          </a>
          </label>
          <input class="form-control" type="tel" name="phone" id="phone" autocomplete="tel-national">
        </div>
        <div class="form-group col-md-6">
          <label for="email">Email</label>
          <input class="form-control" type="email" name="email" id="email" autocapitalize="off" autocorrect="off" autocomplete="email">
        </div>
      </div>
    </fieldset>

    <fieldset class="border-bottom">
      <legend>Address</legend>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="address1">Address 1</label>
          <input type="text" class="form-control" id="address1">
          <small class="form-text text-muted">Street No./Name</small>
        </div>
        <div class="form-group col-md-6">
          <label for="address2">Address 2 <small>(optional)</small></label>
          <input type="text" class="form-control" id="address2">
          <small class="form-text text-muted">Apt. No., PO Box, RR</small>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="city">City</label>
          <input type="text" class="form-control" id="city">
        </div>
        <div class="form-group col-md-6">
          <label for="country">Country</label>
          <select class="form-control" id="country">
            <option>Canada</option>
            <option>United Kingdom</option>
            <option>United States</option>
            <option>Mexico</option>
            <option>Other</option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-8 col-md-6">
          <label for="province">Province</label>
          <select class="form-control" id="province">
            <option value="AB">Alberta</option>
            <option value="BC" selected>British Columbia</option>
            <option value="MB">Manitoba</option>
            <option value="NB">New Brunswick</option>
            <option value="NL">Newfoundland and Labrador</option>
            <option value="NT">Northwest Territory</option>
            <option value="NS">Nova Scotia</option>
            <option value="NU">Nunavut</option>
            <option value="ON">Ontario</option>
            <option value="PE">Prince Edward Island</option>
            <option value="QC">Quebec</option>
            <option value="SK">Saskatchewan</option>
            <option value="YT">Yukon Territory</option>
          </select>
        </div>
        <div class="form-group col-sm-4 col-md-6">
          <label for="frmZipS">
            Postal Code
            <a href="#" data-toggle="popover" data-placement="top" data-content="If you do not know the postal code please use UNK for Canada or NCA otherwise." v-popover>
            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
          </a>
          </label>
          <input class="form-control col-md-6" name="ship-zip" id="frmZipS" autocomplete="shipping postal-code">
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-0">
      <label class="mt-1">
        Is this a public company?
        <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="If a transferee is a public company as defined in section 1 (1) [definitions] of the <a class='link-external' href='http://www.bclaws.ca/civix/document/id/complete/statreg/02057' target='_blank' rel='nofollow noopener'>Business Corporations Act</a>, a return filed under section 2 of the Act must state the name of that public company." v-popover>
          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
        </a>
      </label>
      <div class="form-group">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'publicCompany' + sectionId"  name="publicCompany" class="custom-control-input">
          <label class="custom-control-label" :for="'publicCompany' + sectionId">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'publicCompany2' + sectionId" name="publicCompany" class="custom-control-input">
          <label class="custom-control-label" :for="'publicCompany2' + sectionId">No</label>
        </div>
      </div>

      <div class="form-group">
        <label for="publicCompanyName">Public company name (if different from legal name)</label>
        <input type="text" class="form-control col-md-6" id="publicCompanyName">
      </div>

      <label class="mt-1">
        Is the corporation excluded from the additional information disclosure?
        <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="[Contextual help describing who is excluded from disclosing additional information. Possible link to be included]" v-popover>
          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
        </a>
      </label>
      <div class="form-group">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'corporationExcluded' + sectionId" name="corporationExcluded" class="custom-control-input" @click="excludedFromDisclosure = true">
          <label class="custom-control-label" :for="'corporationExcluded' + sectionId">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'corporationExcluded2' + sectionId" name="corporationExcluded" class="custom-control-input" @click="excludedFromDisclosure = false">
          <label class="custom-control-label" :for="'corporationExcluded2' + sectionId">No</label>
        </div>
      </div>

      <div class="form-group">
        <label for="exclusionType">Exclusion Type</label>
        <select class="form-control col-md-8">
          <option>Select the type of exclusion that applies:</option>
          <option>3(a) – Government Reporting Entity</option>
          <option>3(b) – Government Body</option>
          <option>3(c) – Local Public Body</option>
          <option>3(d) – Public Body</option>
          <option>3(e) – Municipality / Regional District</option>
          <option>3(f) – Public Company</option>
          <option>3(g) – Enactment</option>
          <option>3(h) – Water Utility</option>
          <option>3(i) – Airport Authority</option>
          <option>3(j) – Port Authority</option>
          <option>3(k) – Independent School</option>
          <option>3(l) – Strata Corporation</option>
          <option>3(m) – Savings Institution</option>
          <option>3(n) – Insurance Company</option>
          <option>3(o) – Trust Company</option>
          <option>3(p) – Pension Fund Society</option>
          <option>5(a)(b) – Indigenous Nation</option>
        </select>
      </div>

      <label class="mt-1">
        Does the corporation have any corporate interest holders?
        <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="    A corporate interest holder is an individual who has:
            <ul>
              <li>beneficial and legal ownership in land, or</li>
              <li>a significant interest or control or exerciseable rights in the landowning corporation (25% or more)</li>
            </ul>" v-popover>
          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
        </a>
      </label>
      <div class="form-group">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'corprateInterest1' + sectionId" name="corprateInterest" class="custom-control-input" @click="hasCorporateInterestHolders = true">
          <label class="custom-control-label" :for="'corprateInterest1' + sectionId">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'corprateInterest2' + sectionId" name="corprateInterest" class="custom-control-input" @click="hasCorporateInterestHolders = false">
          <label class="custom-control-label" :for="'corprateInterest2' + sectionId">No</label>
        </div>
      </div>

      <label class="mt-1">
        Is the corporation a "foreign corporation"?
        <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="    [Contextual help with definition of foreign corporation]" v-popover>
          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
        </a>
      </label>
      <div class="form-group">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'foreignCorporation' + sectionId" name="foreignCorporation" class="custom-control-input">
          <label class="custom-control-label" :for="'foreignCorporation' + sectionId">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'foreignCorporation2' + sectionId" name="foreignCorporation" class="custom-control-input">
          <label class="custom-control-label" :for="'foreignCorporation2' + sectionId">No</label>
        </div>
      </div>

      <div class="form-group">
        <label for="province">Jurisdiction of Incorporation</label>
        <select class="form-control col-sm-8 col-md-6" id="province">
          <option>Select jurisdiction</option>
          <option value="AB">Alberta</option>
          <option value="BC" selected>British Columbia</option>
          <option value="MB">Manitoba</option>
          <option value="NB">New Brunswick</option>
          <option value="NL">Newfoundland and Labrador</option>
          <option value="NT">Northwest Territory</option>
          <option value="NS">Nova Scotia</option>
          <option value="NU">Nunavut</option>
          <option value="ON">Ontario</option>
          <option value="PE">Prince Edward Island</option>
          <option value="QC">Quebec</option>
          <option value="SK">Saskatchewan</option>
          <option value="YT">Yukon Territory</option>
        </select>
      </div>

      <label class="mt-1">
        Are any shareholders of the corporation a "foreign entity"?
        <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="    [Contextual help with definition of foreign entity]" v-popover>
          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
        </a>
      </label>
      <div class="form-group">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'foreignEntity' + sectionId" name="foreignEntity" class="custom-control-input">
          <label class="custom-control-label" :for="'foreignEntity' + sectionId">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" :id="'foreignEntity2' + sectionId" name="foreignEntity" class="custom-control-input">
          <label class="custom-control-label" :for="'foreignEntity2' + sectionId">No</label>
        </div>
      </div>
    </fieldset>

    <div class="card mb-4 mt-2" v-if="isTrust">
      <directors
        :sectionId="sectionId * 3"
        :offsetTop="offsetTop"
        sectionTitle="Directors of { Settlor/Beneficiary 1 Name }"
      >
      </directors>
    </div>
    <div class="card mb-4 mt-2" v-if="isTrust && !excludedFromDisclosure && hasCorporateInterestHolders">
      <corporate-interest-holders :sectionId="sectionId * 3"></corporate-interest-holders>
    </div>

      <!-- Save section -->
    <div class="mt-3">
      <button class="btn btn-md btn-tertiary" type="submit" @click="$emit('onSave', index)">Done</button>
      <button class="btn btn-md btn-link" type="button" @click="$emit('onCancel', index)" v-if="!saved">Cancel</button>
    </div>
  </div>
</template>

<script>

export default {

  data () {
    return {
      hasCorporateInterestHolders: false,
      showTrust: false,
      excludedFromDisclosure: false,
      trustExemptFromDisclosure: false
    }
  },

  props: {
    sectionId: Number,
    index: Number,
    saved: Boolean,
    offsetTop: {
      type: Number,
      default: 0
    },
    isTrust: {
      type: Boolean,
      default: false
    }
  },
}
</script>
