<template>
  <div class="common-individual">
    <fieldset class="border-bottom">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" id="lastName">
        </div>
        <div class="form-group col-md-6">
          <label for="givenName">Given Names</label>
          <input type="text" class="form-control" id="givenName">
        </div>
      </div>

      <div class="form-group col-sm-5 col-md-3 px-0">
        <label for="dateOfBirth">Date of Birth</label>
        <div class="input-group">
          <svg class="icon icon-inline-right icon-date position-absolute"><use xlink:href="/icons/symbol-defs.svg#icon-date"></use></svg>
          <input type="text" id="dateOfBirth" name="dateOfBirth" class="form-control">
        </div>
        <small class="form-text text-muted">YYYY-MM-DD</small>
      </div>

      <div class="form-group">
        <label class="mt-1">
          On the date of registration, is the individual a Canadian citizen or permanent resident as defined in the Immigration and Refugee Protection Act (Canada)?
        </label>
        <div class="form-group mt-1">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" :id="'citizenOrPr' + index" name="citizenOrPr" class="custom-control-input">
            <label class="custom-control-label" :for="'citizenOrPr' + index">Yes</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" :id="'citizenOrPr2' + index" name="citizenOrPr" class="custom-control-input">
            <label class="custom-control-label" :for="'citizenOrPr2' + index">No</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="capacity">Country of Citizenship
          <a href="#" data-toggle="popover" data-placement="top" data-content="Identify the foreign country or state of citizenship. If you have more than one foreign citizenship select all applicable." v-popover>
            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
          </a>
        </label>
        <select class="form-control col-md-6" id="capacity">
          <option value="other">multiselect</option>
        </select>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-6">
          <label for="sin">
            Social Insurance Number
            <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="Social Insurance Number (SIN) is a 9 digit number that you need to work in Canada or to have access to government programs. A current SIN must be entered.  To obtain a new SIN or replacement please contact the <a href='https://www.canada.ca/en/employment-social-development/services/sin.html'>Government of Canada</a>  before proceeding with your transfer." v-popover>
              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
            </a>
          </label>
          <input type="text" class="form-control" id="sin">
        </div>

        <div class="form-group col-sm-6">
          <label for="taxNumber">
            Individual Tax Number <small>(optional)</small>
            <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="Individual Tax Number (ITN) is a 9-digit number given to a purchaser who is not a Canadian citizen, but files taxes in Canada, and has applied for a <a href='https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t1261.html'>Canada Revenue Agency (CRA) ITN" v-popover>
              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
            </a>
          </label>
          <input type="text" class="form-control" id="taxNumber">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="phone">
            Phone Number
            <a href="#" data-toggle="popover" data-placement="top" data-content="If calling from outside of North America, enter your international country code." v-popover>
            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
          </a>
          </label>
          <input class="form-control" type="tel" name="phone" id="phone" autocomplete="tel-national">
        </div>
        <div class="form-group col-md-6">
          <label for="email">Email</label>
          <input class="form-control" type="email" name="email" id="email" autocapitalize="off" autocorrect="off" autocomplete="email">
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Address</legend>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="address1">Address 1</label>
          <input type="text" class="form-control" id="address1">
          <small class="form-text text-muted">Street No./Name</small>
        </div>
        <div class="form-group col-md-6">
          <label for="address2">Address 2 <small>(optional)</small></label>
          <input type="text" class="form-control" id="address2">
          <small class="form-text text-muted">Apt. No., PO Box, RR</small>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="city">City</label>
          <input type="text" class="form-control" id="city">
        </div>
        <div class="form-group col-md-6">
          <label for="country">Country</label>
          <select class="form-control" id="country">
            <option>Canada</option>
            <option>United Kingdom</option>
            <option>United States</option>
            <option>Mexico</option>
            <option>Other</option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-8 col-md-6">
          <label for="province">Province</label>
          <select class="form-control" id="province">
            <option value="AB">Alberta</option>
            <option value="BC" selected>British Columbia</option>
            <option value="MB">Manitoba</option>
            <option value="NB">New Brunswick</option>
            <option value="NL">Newfoundland and Labrador</option>
            <option value="NT">Northwest Territory</option>
            <option value="NS">Nova Scotia</option>
            <option value="NU">Nunavut</option>
            <option value="ON">Ontario</option>
            <option value="PE">Prince Edward Island</option>
            <option value="QC">Quebec</option>
            <option value="SK">Saskatchewan</option>
            <option value="YT">Yukon Territory</option>
          </select>
        </div>
        <div class="form-group col-sm-4 col-md-6">
          <label for="postalCode">Postal Code</label>
          <input class="form-control col-md-6" name="ship-zip" id="postalCode" placeholder="10011" autocomplete="shipping postal-code">
        </div>
      </div>
    </fieldset>

    <!-- Save section -->
    <button class="btn btn-md btn-tertiary" type="submit" @click="$emit('onSave', index)">Done</button>
    <button class="btn btn-md btn-link" type="button" @click="$emit('onCancel', index)" v-if="!saved">Cancel</button>
  </div>
</template>

<script>
  export default {
    props: {
      index: Number,
      saved: Boolean,
    },
  }
</script>

