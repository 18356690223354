<template>
  <div id="trust">
    <div class="bg-medium-dark p-2">
      <h3 class="mb-0">Trust Info</h3>
    </div>

    <div class="mb-4 p-2 p-lg-4 bg-medium">
      <div class="form-group">
        <label for="trustName">Trust Name</label>
        <input type="text" class="form-control" id="trustName" />
      </div>

      <div class="form-group">
        <label class="mt-1">
          Is this a bare trust?
          <a
            href="#"
            data-toggle="popover"
            data-placement="top"
            data-content="A bare trust is a basic trust in which the beneficiary has the absolute right to the capital and assets within the trust, as well as the income generated from these assets. However, the trustee has no say in how or when the trust's capital or income is distributed."
            v-popover
          >
            <svg class="icon icon-help">
              <use xlink:href="/icons/symbol-defs.svg#icon-help"></use>
            </svg>
          </a>
        </label>
        <div class="form-group">
          <div class="custom-control custom-radio custom-control-inline mt-1">
            <input
              type="radio"
              id="bareTrust"
              name="bareTrust"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="bareTrust">Yes</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="bareTrust2"
              name="bareTrust"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="bareTrust2">No</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="mt-1">
          Is the trust exempt from the additional information disclosure?
          <a
            href="#"
            data-toggle="popover"
            data-placement="top"
            data-content="[Contextual help describing who is excluded from disclosing additional information. Possible link to be included]"
            v-popover
          >
            <svg class="icon icon-help">
              <use xlink:href="/icons/symbol-defs.svg#icon-help"></use>
            </svg>
          </a>
        </label>
        <div class="form-group">
          <div class="custom-control custom-radio custom-control-inline mt-1">
            <input
              type="radio"
              id="exemptFromDisclosure"
              name="exemptFromDisclosure"
              class="custom-control-input"
              @click="$emit('onExemptFromDisclosure', true)"
            />
            <label class="custom-control-label" for="exemptFromDisclosure"
              >Yes</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="exemptFromDisclosure2"
              name="exemptFromDisclosure"
              class="custom-control-input"
              @click="$emit('onExemptFromDisclosure', false)"
            />
            <label class="custom-control-label" for="exemptFromDisclosure2"
              >No</label
            >
          </div>
        </div>
      </div>

      <div class="form-group" v-if="exemptFromDisclosure">
        <label for="trustType">
          Trust Type
        </label>
        <select class="form-control col-sm-9 col-md-8 col-lg-6" id="trustType">
          <option value="Select the type of trust that applies:"
            >Select the type of trust that applies</option
          >
          <option value="Bankruptcy">Bankruptcy</option>
          <option value="Charitable">Charitable</option>
          <option value="Indigenous Nation/Band">Indigenous Nation/Band</option>
          <option value="Mutual Funds">Mutual Funds</option>
          <option value="Pension Plan">Pension Plan</option>
          <option value="Pooled Registered Pension Plan"
            >Pooled Registered Pension Plan</option
          >
          <option value="Public Guardian and Trustee"
            >Public Guardian and Trustee</option
          >
          <option value="Real Estate Investment">Real Estate Investment</option>
          <option value="Sift">Sift</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    exemptFromDisclosure: Boolean
  }
};
</script>
