<template>
  <div :id="'settlorsBeneficiaries' + sectionId">
    <div
      class="d-md-flex justify-content-between bg-medium-dark p-2 sticky-top"
      ref="sectionHeader"
    >
      <h3 class="mb-0">Settlors/Beneficiaries of the Trust</h3>
      <div class="mt-1 mt-md-0 text-nowrap" v-if="settlors.length > 1">
        <button class="btn btn-link btn-sm px-1" @click="expandAll">
          Expand all
        </button>
        <span>|</span>
        <button class="btn btn-link btn-sm px-1" @click="collapseAll">
          Collapse all
        </button>
      </div>
    </div>

    <div class="p-2 p-lg-4 bg-medium">
      <div class="card mb-2" v-for="(settlor, index) in settlors" :key="index">
        <!-- Settlors beneficiaries  collapse -->
        <div class="d-flex px-2 py-1">
          <button
            class="btn collapse-btn"
            data-toggle="collapse"
            :data-target="'#settlor' + index + sectionId"
            :aria-expanded="settlor.collapsed"
            :aria-controls="'settlor' + index"
            @click="settlors[index].collapsed = !settlors[index].collapsed"
          >
            <svg class="icon icon-arrow_right collapse-icon">
              <use
                xlink:href="/icons/symbol-defs.svg#icon-arrow_right"
              ></use>
            </svg>
            <strong>Settlor/Beneficiary {{ index + 1 }}</strong>
            <span v-if="settlor.collapsed">
              - { Settlor/Beneficiary Name }</span
            >
          </button>
          <button
            class="btn btn-link btn-sm text-danger pr-0"
            v-if="settlor.collapsed"
            @click="settlorToDelete = index"
            data-toggle="modal"
            :data-target="'#deleteModal' + sectionId"
          >
            Delete
          </button>
        </div>

        <div
          :id="'settlor' + index + sectionId"
          class="collapse card-body p-lg-4 bg-light border-top"
          :class="{ show: !settlor.collapsed }"
          aria-labelledby="settlorHeading"
        >
          <div class="row mb-2">
            <div class="col-12">
              <div class="form-group mt-1">
                <label>This is:</label>
                <div class="input-group mt-1">
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      :id="'settlorOrBeneficiary' + index"
                      name="settlorOrBeneficiary"
                      class="custom-control-input"
                    />
                    <label
                      class="custom-control-label"
                      :for="'settlorOrBeneficiary' + index"
                      >Settlor</label
                    >
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      :id="'settlorOrBeneficiary2' + index"
                      name="settlorOrBeneficiary"
                      class="custom-control-input"
                    />
                    <label
                      class="custom-control-label"
                      :for="'settlorOrBeneficiary2' + index"
                      >Beneficiary</label
                    >
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      :id="'settlorOrBeneficiary3' + index"
                      name="settlorOrBeneficiary"
                      class="custom-control-input"
                    />
                    <label
                      class="custom-control-label"
                      :for="'settlorOrBeneficiary3' + index"
                      >Both</label
                    >
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label :for="'type' + index">Type</label>
                <select
                  class="form-control col-sm-8 col-lg-6"
                  :id="'type' + index"
                  v-model="settlor.type"
                >
                  <option value="">Select Type</option>
                  <option value="individual">Individual</option>
                  <option value="corporation">Corporation</option>
                  <option value="corporation">Association</option>
                  <option value="corporation">Financial Institution</option>
                  <option value="corporation">Government</option>
                  <option value="corporation">Society</option>
                  <option value="corporation">Strata Corporation</option>
                  <option value="other">None of the above</option>
                </select>
              </div>

              <!-- If none of above is selected -->
              <template v-if="settlor.type == 'other'">
                <div class="form-group">
                  <label for="other">Specify type</label>
                  <input type="text" class="form-control" id="other" />
                </div>

                <div class="mt-3">
                  <button
                    class="btn btn-md btn-tertiary"
                    type="submit"
                    @click="saveSettlor(index)"
                  >
                    Done
                  </button>
                  <button
                    class="btn btn-md btn-tertiary"
                    type="button"
                    @click="cancelSettlor(index)"
                  >
                    Cancel
                  </button>
                </div>
              </template>

              <common-individual
                :isTrust="true"
                :sectionId="index + sectionId"
                :index="index"
                :saved="settlor.saved"
                v-if="settlor.type == 'individual'"
                @onSave="saveSettlor"
                @onCancel="cancelSettlor"
              ></common-individual>
              <common-corporation
                :isTrust="true"
                :sectionId="index + sectionId"
                :index="index"
                :offsetTop="offsetTop"
                :saved="settlor.saved"
                v-if="settlor.type == 'corporation'"
                @onSave="saveSettlor"
                @onCancel="cancelSettlor"
              ></common-corporation>
            </div>
          </div>
        </div>
      </div>

      <button
        class="btn btn-link mt-2 pl-0"
        @click="addSettlor()"
        v-if="editingSettlor"
      >
        + Add Another Settlor/Beneficiary
      </button>
    </div>

    <div
      class="modal fade"
      :id="'deleteModal' + sectionId"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="deleteModalLabel">Delete Director</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><svg class="icon icon-close">
                  <use
                    xlink:href="/icons/symbol-defs.svg#icon-close"
                  ></use></svg
              ></span>
            </button>
          </div>
          <div class="modal-body">
            <h1 class="text-danger">
              <svg class="icon icon-lg icon-warning">
                <use
                  xlink:href="/icons/symbol-defs.svg#icon-warning"
                ></use>
              </svg>
              Warning
            </h1>
            <p>
              Are you sure you want to delete this settlor/beneficiary? This
              action <span class="text-danger">cannot be undone</span>.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="deleteSettlor">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonIndividual from "./CommonIndividual.vue";
import CommonCorporation from "./CommonCorporation.vue";

export default {
  components: {
    "common-individual": CommonIndividual,
    "common-corporation": CommonCorporation
  },

  props: {
    sectionId: Number
  },

  data() {
    return {
      settlors: [{ type: "", saved: false, collapsed: false }],
      settlorToDelete: null,
      offsetTop: 0
    };
  },

  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getOffsetTop);

      this.getOffsetTop();
    });
  },

  computed: {
    editingSettlor() {
      return this.settlors.every(({ collapsed }) => collapsed === true);
    }
  },

  methods: {
    getOffsetTop(event) {
      this.offsetTop = this.$refs.sectionHeader.clientHeight;
    },

    addSettlor() {
      this.settlors.push({ type: "", collapsed: false });
    },

    saveSettlor(index) {
      this.settlors[index].collapsed = true;
      this.settlors[index].saved = true;
      document
        .getElementById("settlorsBeneficiaries" + this.sectionId)
        .scrollIntoView();
    },

    cancelSettlor(index) {
      this.settlors[index].type = "";
    },

    deleteSettlor() {
      this.settlors.splice(this.settlorToDelete, 1);
      $("#deleteModal" + this.sectionId).modal("hide");
    },

    expandAll() {
      this.directors.forEach(director => {
        director.collapsed = false;
      });
    },

    collapseAll() {
      this.directors.forEach(director => {
        director.collapsed = true;
      });
    }
  }
};
</script>
